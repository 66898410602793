@import "tailwindcss/base";
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-2xl;
    @apply mb-2;
  }
  h2 {
    @apply text-xl;
    @apply mb-2;
  }
  h3 {
    @apply text-xl;
    @apply mb-2;
  }
  p {
    @apply mb-4;
  }
}

svg {
  vertical-align: baseline;
}

body {
  font-family: "Poppins", sans-serif;
}

h2 {
  font-weight: 200;
}

.demo-infinite-container {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 0px;
  height: 500px;
}
.demo-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}
::-webkit-scrollbar {
  width: 6px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.file-preview-table-container {
  width: 100%;
  max-height: 450px;
  overflow: scroll;
  border: 1px solid #ddd;
  border-radius: 2px;
}
.table-column-selected {
  position: relative;
}
.file-preview-table-container tr > th.table-column-selected {
  background: rgba(0, 0, 255, 0.3) !important;
  font-weight: bold !important;
}
.file-preview-table-container td.table-column-selected {
  font-weight: bold !important;
}

.ant-modal-header {
  text-align: left;
}

.ant-select-dropdown-menu {
  text-align: left;
}
.ant-modal-confirm-body {
  text-align: left;
}

.input-subheader {
  font-weight: 700;
  color: black;
  margin: 8px 0px;
}

.ant-input-search-icon {
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-input-search-icon:hover {
  color: rgba(0, 0, 0, 0.8);
}
.ant-input-search-enter-button input {
  border-right: 0;
}
.ant-input-search-enter-button + .ant-input-group-addon,
.ant-input-search-enter-button input + .ant-input-group-addon {
  padding: 0;
  border: 0;
}
.ant-input-search-enter-button
  + .ant-input-group-addon
  .ant-input-search-button,
.ant-input-search-enter-button
  input
  + .ant-input-group-addon
  .ant-input-search-button {
  width: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-select-dropdown {
  z-index: 3000;
}

.mapboxgl-canvas {
  left: 0;
}

.story-text {
  word-break: break-word;
}

.story-text > p img {
  width: 100%;
}

.form {
  flex: 3;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 6px;
  box-shadow:
    3px 3px 3px rgba(0, 0, 0, 0.08),
    0px 3px 3px rgba(0, 0, 0, 0.08);
}

.form-field {
  height: 50px;
  width: 250px;
  line-height: 30px;
  vertical-align: middle;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #eee;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #ddd;
  /* background-color: #eee; */
  cursor: move;
}

.label {
  margin-bottom: 5px;
}

.field {
  cursor: auto;
}

.field input,
.field textarea,
.field select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
  outline: none;
  transition: border-color 0.3s ease;
}

.field input[type="radio"],
.field input[type="checkbox"] {
  width: auto;
}

.field input:focus,
textarea:focus {
  border: 1px solid #80bdff;
  box-shadow: 0 0 3px 0px #80bdff;
}

input:last-child {
  margin-right: 0;
}

.dragging {
  background-color: yellow;
}

.card-container {
  width: 320px;
  padding: 10px;
  margin: 5px;
  margin-right: 45px;
  background-color: #f3f3f3;
}

.card {
  margin: 5px;
  background-color: white;
  box-shadow:
    0 1px 1px rgba(0, 0, 0, 0.12),
    0 1px 1px rgba(0, 0, 0, 0.24);
  padding: 10px;
}

.column-drag-handle {
  cursor: move;
  padding: 5px;
}

.drop-zone {
  display: flex;
  flex-wrap: wrap;
}

.drop-zone-container {
  transition: background-color 0.2s ease;
  width: 100px;
  height: 100px;
  border: 5px solid #348;
  background-color: #80bdff;
  border-radius: 100px;
  margin: 20px;
}

.drop-zone-container.hover {
  background-color: yellow;
  transform: scale(1.02);
}

.drop-zone-draggable {
  width: 80px;
  height: 80px;
  border: 2px solid #348;
  background-color: cornflowerblue;
  border-radius: 100px;
  margin: 10px;
}

.row {
  display: flex;
}

/*End drag and drop stuff*/

body {
  margin: 0;
  padding: 0;
  color: black !important;
}

.ql-editor {
  font-size: 16px !important;
}

.story-text li {
  list-style-type: disc;
  padding-left: 16px;
  margin-left: 16px;
}

.story-text ul {
  margin: 0px;
}

.story-text p {
  margin: 0px;
}

.story-text h2 {
  margin: 0px;
}

.react-add-to-calendar__button {
  background-color: #65a1e7;
  color: white;
  padding: 8px;
  border-radius: 2px;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}

.react-add-to-calendar__dropdown {
  width: 181.25px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  padding-left: 6px;
  padding-top: 1px;
}

li {
  list-style-type: none;
}

ul {
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

div.tab > div:first-child {
  display: flex;
}

#block {
  display: block;
}

.ReactTable .rt-tr .action {
  transition: all 0.2s ease;
  text-align: center;
  color: red;
  transform: scale(0);
}

.ReactTable .rt-tr:hover .action {
  transform: scale(1.3);
}

.ReactTable .rt-tr:hover .rt-td {
  background: #ffcb00;
  cursor: pointer;
}

.skip-step-button {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

button {
  background: none;
  color: inherit;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.z-50 {
  z-index: 50;
}

.booking-rules-text {
  font-weight: 600;
  font-size: 16px;
}

.booking-rules-text input {
  font-weight: 600;
}

.ant-form-item.ant-form-item-has-error.overflow-error {
  white-space: nowrap;
  margin-bottom: 22px;
}

.ant-form-item.overflow-error
  .ant-form-item-explain.ant-form-item-explain-error {
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
}

.rules-discount-value input {
  padding: 0 9px;
}

.ant-input-number.hide-controls .ant-input-number-handler-wrap {
  display: none;
}

/* REPORT FILTER UI */

.filter-specification {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.filter-clause-element {
  display: flex;
  border: 2px #e9ecef solid;
  border-radius: 10px;
}

.filter-clause-element.error {
  border-color: red;
}

.filter-clause-sections {
  display: flex;
}

.filter-remove {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-width: 2px;
  border-left-width: 0;
  cursor: pointer;
}

.filter-remove:hover {
  color: #6868f7;
}

.filter-clause-remove {
  display: flex;
  justify-items: center;
  align-items: center;
  border-left: #e9ecef 2px solid;
  padding: 0 6px;
  cursor: pointer;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.filter-clause-element.error .filter-clause-remove {
  border-color: red;
}

.filter-clause-element .ant-select-selector {
  visibility: hidden;
}

.filter-clause-remove:hover {
  color: #6868f7;
  background-color: #f0f0fe;
}

.filter-clause-sections > div .clause-section-description {
  border-left-width: 2px;
  border-style: dotted;
  border-color: #dce0e3;
  cursor: pointer;
  padding: 2px 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  white-space: nowrap;
  height: 100%;
}

.filter-clause-element.error
  .filter-clause-sections
  > div:last-child
  .clause-section-description {
  border-color: red;
}

.filter-clause-sections > div:last-child .clause-section-description {
  white-space: normal;
}

.filter-clause-sections > div .clause-section-description:hover {
  --tw-bg-opacity: 1;
  background-color: #f0f0fe;
}

.filter-clause-sections
  > div
  .clause-section-description:hover
  .ant-typography {
  color: #6868f7;
}

.filter-clause-sections > div:first-child .clause-section-description {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-left-width: 0;
}

.reports-filter-tabs-menu {
  box-shadow:
    0 3px 6px -4px rgb(0 0 0 / 12%),
    0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%) !important;
}

.reports-filter-tabs .button:hover {
  color: #40a9ff;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.reports-filter-tabs .tab.error:not(.active) .button {
  border-color: red;
  color: red;
}

.reports-filter-tabs .tab-content.error .missing-field {
  color: red;
}

.reports-filter-tabs .tab.active .button {
  height: calc(100% + 5px);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-width: 0;
}

.recharts-wrapper.overflow-visible .recharts-surface {
  overflow: visible;
}

.reports-cascader .ant-cascader-menu-item {
  transition: none !important;
}

.reports-color-badge .ant-badge-status-text {
  margin: 0;
}

.reports-color-badge.active .ant-badge-status-dot {
  border: 1px solid black;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.hide-scrollbar::-webkit-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.card-container p {
  margin: 0;
}
.card-container > .ant-tabs-card .ant-tabs-content {
  margin-top: -16px;
}
.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 16px;
  background: #fff;
}
.card-container > .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}
.card-container > .ant-tabs-card .ant-tabs-tab,
[data-theme="compact"] .card-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}
.card-container > .ant-tabs-card .ant-tabs-tab-active,
[data-theme="compact"] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #fff;
  border-color: #fff;
}
#components-tabs-demo-card-top .code-box-demo {
  padding: 24px;
  overflow: hidden;
  background: #f5f5f5;
}

[data-theme="compact"] .card-container > .ant-tabs-card .ant-tabs-content {
  height: 120px;
  margin-top: -8px;
}
[data-theme="dark"] .card-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}
[data-theme="dark"] #components-tabs-demo-card-top .code-box-demo {
  background: #000;
}

[data-theme="dark"]
  .card-container
  > .ant-tabs-card
  .ant-tabs-content
  > .ant-tabs-tabpane {
  background: #141414;
}

[data-theme="dark"] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #141414;
  border-color: #141414;
}

.report-manager-tabs .ant-tabs-nav-list {
  margin-left: 24px;
}

.report-manager-tabs .ant-tabs-tab {
  margin-left: 8px !important;
}

.report-details-table tr.active td {
  background-color: #0087fe1c !important;
  color: #0088fe !important;
}

.report-details-table tr.active td {
  background-color: #0087fe1c !important;
  color: #0088fe !important;
}

.report-details-table tr.ant-table-expanded-row > td {
  background-color: white;
  border: 8px solid #0087fe1c;
  border-top-width: 0;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  padding: 20px;
}

.report-details-table th:first-child .ant-table-column-sorter {
  margin-left: 0;
}

.report-details-table td {
  user-select: none;
}

.ant-select.no-wrap .ant-select-selection-overflow {
  flex-wrap: nowrap;
}

.recharts-label.anchor-end {
  text-anchor: end;
}

.recharts-legend-item-text {
  position: relative;
  top: 2px;
}

.report-table .ant-pagination {
  margin-bottom: 0;
  justify-content: center;
}

:root {
  --color-highlight-blue: #40a9ff;
}

.chain-item-popover .ant-popover-content {
  .ant-popover-inner {
    border-radius: 0.5rem;
    padding: 0;
  }
}

.subscription-modal .ant-modal-content {
  padding: 0 !important;
}

.subscription-modal .ant-modal-footer {
  display: none;
}

.subscription-modal .ant-modal-close {
  display: none;
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.animate-float {
  animation: float 3s ease-in-out infinite;
}

.text-shadow {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
